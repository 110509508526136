import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
export const ShowcaseDots = (props) => {
    const { slidesCount, currentSlide, scrollToSlide } = props;
    const dots = new Array(slidesCount - 1).fill(null);
    return (<div className={styles.showcaseDots}>
      {dots.map((_, index) => (<div key={index} className={classNames(styles.showcaseDots__indicator, {
                [styles.showcaseDots__indicator_active]: index === currentSlide,
                [styles.showcaseDots__indicator_next_to_active]: index === currentSlide - 1 || index === currentSlide + 1,
            })} onClick={() => scrollToSlide(index)}>
          <div className={styles.showcaseDots__area}/>
        </div>))}
    </div>);
};
