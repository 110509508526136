import React from 'react';
export const LogoMore = () => (<svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_116_18113)'>
      <rect width='44' height='44' rx='10' fill='white'/>
      <path d='M0.410299 16.5946C-1.3929 16.7841 -2.96994 17.976 -3.62704 19.6448C-3.77375 20.0207 -3.90211 20.5341 -3.9449 20.9253C-3.96629 21.1332 -3.97852 22.316 -3.97852 24.4462V27.6553H-3.13804H-2.30062L-2.29145 24.2689C-2.28228 20.8948 -2.28228 20.8856 -2.21504 20.6503C-1.90025 19.547 -1.11173 18.7126 -0.0817618 18.3856C0.281935 18.2694 0.5081 18.2389 0.920697 18.2389C1.77645 18.2419 2.49468 18.5292 3.10899 19.116C3.55826 19.5439 3.85472 20.0421 4.02282 20.6503L4.10534 20.9467L4.11451 24.2995L4.12368 27.6553H4.94582H5.76795L5.78018 24.3759C5.78935 21.4266 5.79546 21.0782 5.8413 20.8856C6.00634 20.2132 6.2753 19.7212 6.73068 19.2627C7.56199 18.4284 8.76311 18.1166 9.88782 18.4437C10.4441 18.6056 10.8475 18.8471 11.2723 19.278C11.7949 19.8037 12.0792 20.3813 12.1892 21.1301C12.2075 21.2615 12.2198 22.5543 12.2198 24.4981V27.6553H13.045H13.8732L13.861 24.2108C13.8518 20.7817 13.8518 20.7603 13.7846 20.4669C13.4881 19.168 12.7485 18.083 11.688 17.3831C10.0712 16.3165 7.9593 16.3104 6.34253 17.3648C5.84436 17.6888 5.43788 18.0769 5.01611 18.627L4.94582 18.7157L4.89386 18.6393C3.88223 17.212 2.13098 16.4143 0.410299 16.5946Z' fill='black'/>
      <path d='M21.3178 16.594C19.0164 16.759 17.0237 18.3391 16.3147 20.5488C15.572 22.8746 16.388 25.4052 18.3532 26.8722C19.3923 27.6455 20.6301 28.0275 21.9504 27.9817C23.0415 27.945 24.0318 27.6241 24.9334 27.0189C27.8643 25.0507 28.3136 20.8911 25.8655 18.3361C24.6828 17.1013 23.0293 16.4687 21.3178 16.594Z' fill='black'/>
      <path d='M34.1334 16.5946C32.9872 16.6771 31.9206 17.1661 31.0893 17.9822C30.313 18.7493 29.824 19.7181 29.6742 20.7878C29.6529 20.9498 29.6406 22.1265 29.6406 24.3423V27.6553H30.4658H31.291V24.4523C31.291 21.8117 31.3002 21.2004 31.3369 20.962C31.4377 20.2896 31.7495 19.6723 32.2354 19.1863C32.7916 18.6331 33.5252 18.3 34.2953 18.2511L34.5918 18.2328V17.3954V16.561L34.5246 16.564C34.4848 16.5671 34.3106 16.5793 34.1334 16.5946Z' fill='black'/>
      <path d='M41.2084 16.6121C40.8875 16.6427 40.3587 16.7558 39.992 16.878C38.0207 17.526 36.5873 19.155 36.1686 21.2149C35.8843 22.6208 36.193 24.1886 36.9938 25.4173C38.0451 27.031 39.8178 27.9937 41.7432 27.9998C42.3147 28.0029 42.6051 27.9662 43.143 27.8348C43.8276 27.6667 44.5153 27.355 45.0746 26.9576C45.7255 26.4961 46.456 25.674 46.78 25.0352L46.8411 24.9191L46.1198 24.5157L45.3955 24.1153L45.3282 24.2284C45.0898 24.6318 44.934 24.8335 44.6161 25.1514C44.1363 25.6312 43.6748 25.9216 43.0696 26.1263C42.5959 26.2883 42.3575 26.325 41.7738 26.325C41.1931 26.325 40.9516 26.2883 40.481 26.1294C39.8789 25.9246 39.3899 25.6159 38.9162 25.1361C38.2468 24.4607 37.932 23.8219 37.7578 22.7705L37.7425 22.6727H42.599H47.4554L47.4768 22.4955C47.5043 22.2876 47.4493 21.5572 47.379 21.2179C46.8839 18.7668 44.9584 16.9575 42.4981 16.6366C42.1742 16.5969 41.5293 16.5816 41.2084 16.6121ZM42.5745 18.3359C43.7053 18.562 44.6803 19.265 45.2671 20.2674C45.3985 20.4936 45.5544 20.8359 45.6094 21.0132L45.6277 21.0835H41.7768C39.6558 21.0835 37.9229 21.0743 37.9229 21.059C37.9229 21.0101 38.0543 20.6984 38.1796 20.4478C38.7817 19.2558 39.9859 18.4276 41.3398 18.2717C41.6668 18.235 42.22 18.2625 42.5745 18.3359Z' fill='black'/>
    </g>
    <defs>
      <clipPath id='clip0_116_18113'>
        <rect width='44' height='44' rx='10' fill='white'/>
      </clipPath>
    </defs>
  </svg>);
