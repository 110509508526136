import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import classNames from 'classnames';
import { Spinner } from '@vkontakte/vkui';
import { createAnalyticClickAttribute, getDefaultMetrics } from '@analytics';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { errorLog } from '@common/shared/lib/utils';
export const ShowcaseOfferButton = (props) => {
    const { buttonContent, className: propsClassName, applyOffer, ...restProps } = props;
    const { name, metrics } = buttonContent;
    const [uiState, setUiState] = useState('idle');
    const subscriptionsState = useUnit(SubscriptionsModel.$subscriptionsState);
    useEffect(() => {
        if (subscriptionsState === 'loading' || subscriptionsState === 'updating') {
            setUiState('loading');
            return;
        }
        setUiState('idle');
    }, [subscriptionsState]);
    const handlerClick = async () => {
        setUiState('loading');
        try {
            await applyOffer();
        }
        catch (e) {
            errorLog(e);
        }
        finally {
            setUiState('idle');
        }
    };
    return (<button {...createAnalyticClickAttribute(getDefaultMetrics(metrics, 'click', 'landing-showcase-offers-button'))} {...restProps} type={'button'} disabled={uiState === 'loading'} className={classNames(styles.showcaseOfferButton, propsClassName)} onClick={handlerClick}>
      {uiState === 'loading' ? <Spinner style={{ color: '#fff' }}/> : <Typograf>{name}</Typograf>}
    </button>);
};
