import React from 'react';
export const LogoMusic = () => {
    return (<svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.09286 3.09295C0.0924445 6.09338 0.00268301 10.8678 0 20.2397C8.39872 20.2397 13.6424 16.634 18.2421 13.4711C21.8354 11.0003 25.0356 8.79973 29.0399 8.79973C34.9999 8.79973 40.2038 13.0138 42.9632 15.2483C43.3523 15.5634 43.6928 15.8391 43.98 16.0581C43.8928 9.37613 43.4242 5.61022 40.907 3.09295C37.814 1.46866e-06 32.836 1.02425e-06 22.8799 1.53864e-07L21.1199 0C11.1638 2.48655e-06 6.18582 1.88828e-06 3.09286 3.09295Z' fill='#0077FF'/>
      <path d='M19.8263 43.9997C20.2479 44 20.679 44 21.1199 44L22.8799 44C23.3207 44 23.7518 44 24.1734 43.9997L19.8263 43.9997Z' fill='#0077FF'/>
      <path d='M8.41551e-05 20.2398C8.3988 20.2398 13.6425 16.6341 18.2422 13.4712C21.8355 11.0004 25.0357 8.7998 29.04 8.7998C35 8.79981 40.2039 13.0139 42.9633 15.2483C43.3524 15.5634 43.6929 15.8392 43.9801 16.0582C44 17.5863 44 19.267 44 21.1201L44 22.8801C44 32.8361 44 37.8142 40.907 40.9071C37.951 43.8632 33.2731 43.994 24.1732 43.9998L19.8267 43.9998C10.7269 43.994 6.04898 43.8632 3.09295 40.9071C-1.46866e-06 37.8142 -8.69464e-07 32.8361 9.2271e-10 22.8801L1.54787e-07 21.1201C1.80826e-07 20.8222 2.06479e-07 20.5288 8.41551e-05 20.2398Z' fill='#F45FFF'/>
      <path d='M34.1001 22.4299C34.1001 21.3425 34.9927 20.4609 36.0801 20.4609C37.1676 20.4609 38.0601 21.3425 38.0601 22.4299L38.0601 30.3719C38.0601 31.4594 37.1676 32.3409 36.0801 32.3409C34.9927 32.3409 34.1001 31.4594 34.1001 30.3719L34.1001 22.4299Z' fill='white'/>
      <path d='M27.0601 17.3674C27.0601 16.28 27.9526 15.3984 29.0401 15.3984C30.1275 15.3984 31.0201 16.28 31.0201 17.3674L31.0201 35.4294C31.0201 36.5169 30.1275 37.3984 29.0401 37.3984C27.9526 37.3984 27.0601 36.5169 27.0601 35.4294L27.0601 17.3674Z' fill='white'/>
      <path d='M20.0205 22.4299C20.0205 21.3425 20.9131 20.4609 22.0005 20.4609C23.088 20.4609 23.9805 21.3425 23.9805 22.4299L23.9805 30.3719C23.9805 31.4594 23.088 32.3409 22.0005 32.3409C20.9131 32.3409 20.0205 31.4594 20.0205 30.3719L20.0205 22.4299Z' fill='white'/>
      <path d='M12.979 25.2883C12.979 24.2009 13.8716 23.3193 14.959 23.3193C16.0464 23.3193 16.939 24.2009 16.939 25.2883L16.939 27.5103C16.939 28.5978 16.0464 29.4793 14.959 29.4793C13.8716 29.4793 12.979 28.5978 12.979 27.5103L12.979 25.2883Z' fill='white'/>
      <path d='M5.93945 25.2883C5.93945 24.2009 6.832 23.3193 7.91945 23.3193C9.0069 23.3193 9.89944 24.2009 9.89944 25.2883L9.89944 27.5103C9.89944 28.5978 9.0069 29.4793 7.91945 29.4793C6.832 29.4793 5.93945 28.5978 5.93945 27.5103L5.93945 25.2883Z' fill='white'/>
    </svg>);
};
