import React from 'react';
import styles from './styles.module.scss';
import SkeletonItem from '@common/components/common/SkeletonItem';
export const ShowcaseSkeleton = () => {
    return (<div>
      <SkeletonItem className={styles.showcaseSkeleton__title}/>
      <div className={styles.showcaseSkeleton__items}>
        <SkeletonItem className={styles.showcaseSkeleton__item}/>
        <SkeletonItem className={styles.showcaseSkeleton__item}/>
      </div>
    </div>);
};
