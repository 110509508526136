const getOfferContent = (offer) => {
    return {
        ...offer.widgets?.showcaseOffer,
        correlationId: offer.correlationId,
        offerTags: offer.tags,
        with: offer.with,
    };
};
export const getOffersContent = (offers) => offers.reduce((acc, offer) => {
    if (!offer?.widgets?.showcaseOffer) {
        return acc;
    }
    return [...acc, getOfferContent(offer)];
}, []);
