import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import { Icon28ChevronLeft } from '@vkontakte/icons';
import classNames from 'classnames';
import useSize from '@react-hook/size';
import { IS_MINIAPP } from '@common/consts';
import { createAnalyticShowAttribute } from '@analytics';
import SkeletonItem from '@common/components/common/SkeletonItem';
import { showcaseOffersModel } from './model';
import { ShowcaseOffer, ShowcaseOffersTabs } from './ui';
import styles from './styles.module.scss';
const { $uiState, changeUiState, getTabs, getOffersContent, getTabsContent } = showcaseOffersModel;
export const ShowcaseOffersV1 = (props) => {
    const { defaultTabId, offers, offersState, applyOffer } = props;
    const uiState = useUnit($uiState);
    const contentRef = useRef(null);
    const [widthContent] = useSize(contentRef.current);
    const [scrollPosition, setScrollPosition] = useState('prev');
    const [showContentNavButton, setShowContentNavButton] = useState(true);
    const scrollContentNext = () => {
        if (!contentRef.current) {
            return;
        }
        contentRef.current?.scrollTo({ left: contentRef.current?.scrollWidth, top: 0, behavior: 'smooth' });
        setScrollPosition('next');
    };
    const scrollContentPrev = () => {
        if (!contentRef.current) {
            return;
        }
        contentRef.current?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        setScrollPosition('prev');
    };
    const onScrollContent = (event) => {
        if (!event?.currentTarget) {
            return;
        }
        const { scrollLeft, scrollWidth, offsetWidth } = event.currentTarget;
        if (scrollLeft === 0) {
            setScrollPosition('prev');
            return;
        }
        if (scrollWidth - offsetWidth - scrollLeft === 0) {
            setScrollPosition('next');
            return;
        }
        setScrollPosition('center');
    };
    useEffect(() => {
        if (!contentRef.current) {
            setShowContentNavButton(false);
            return;
        }
        const isShow = contentRef.current?.scrollWidth - contentRef.current?.offsetWidth > 0;
        setShowContentNavButton(isShow);
    }, [widthContent, uiState]);
    const pendingGetOffers = offersState === 'loading' || offersState === 'updating';
    const maxLengthBenefits = useMemo(() => {
        return Math.max.apply(null, offers.map((item) => item?.widgets?.showcaseOffer?.benefits?.length || 0));
    }, [offers]);
    const tabs = useMemo(() => getTabs(offers), [props]);
    const initTabId = useMemo(() => {
        const defaultTab = tabs.find((item) => item.id === defaultTabId);
        if (defaultTab) {
            return defaultTabId;
        }
        return tabs?.[0]?.id;
    }, [props]);
    const [contentId, setContentId] = useState(initTabId);
    useEffect(() => {
        setContentId(initTabId);
    }, [initTabId]);
    const renderTabContent = useMemo(() => {
        const renderOffer = (item) => (<ShowcaseOffer offer={item} key={item.correlationId} maxLengthBenefits={maxLengthBenefits} applyOffer={applyOffer}/>);
        if (!contentId || tabs.length === 0) {
            return getOffersContent(offers)?.map(renderOffer);
        }
        return getTabsContent(offers)[contentId]?.map(renderOffer);
    }, [props, contentId]);
    useEffect(() => {
        if (renderTabContent && renderTabContent.length > 0 && !pendingGetOffers) {
            changeUiState('idle');
            return;
        }
        changeUiState('skeleton');
    }, [renderTabContent, pendingGetOffers]);
    const renderTabs = () => {
        if (uiState === 'skeleton') {
            return (<div className={styles.showcaseOffers__tabsContainerSkeleton}>
          <SkeletonItem className={styles.showcaseOffers__tabItemSkeleton}/>
          <SkeletonItem className={styles.showcaseOffers__tabItemSkeleton}/>
        </div>);
        }
        return ((tabs.length > 1 || !contentId) && (<ShowcaseOffersTabs tabs={tabs} activeTab={contentId || ''} setContentId={setContentId}/>));
    };
    const renderOffers = () => {
        if (uiState === 'skeleton') {
            return (<div className={styles.container}>
          <div className={styles.showcaseOffers__contentSkeleton}>
            <SkeletonItem className={styles.showcaseOffers__offerSkeleton}/>
            <SkeletonItem className={styles.showcaseOffers__offerSkeleton}/>
            <SkeletonItem className={styles.showcaseOffers__offerSkeleton}/>
          </div>
        </div>);
        }
        return (<div className={styles.showcaseOffers__contentWrapper}>
        <div ref={contentRef} onScroll={onScrollContent} className={styles.showcaseOffers__content}>
          {renderTabContent}
        </div>
        <div className={styles.showcaseOffers__contentLeftShadowCard}/>
        <div className={styles.showcaseOffers__contentRightShadowCard}/>
      </div>);
    };
    return (<div className={classNames(styles.showcaseOffers, { [styles.showcaseOffers__miniapp]: IS_MINIAPP })} {...createAnalyticShowAttribute('landing-showcase-offers')}>
      {uiState === 'skeleton' ? (<div className={styles.container}>
          <SkeletonItem className={styles.showcaseOffers__titleSkeleton}/>
        </div>) : (<div className={classNames({ [styles.container]: !IS_MINIAPP }, styles.showcaseOffers__titleContainer)}>
          <h2 className={styles.showcaseOffers__title}>выберите подписку</h2>{' '}
          {showContentNavButton && (<div className={styles.showcaseOffers__contentNavButtonContainer}>
              <button disabled={scrollPosition === 'prev'} className={styles.showcaseOffers__contentNavButton} onClick={() => scrollContentPrev()}>
                <Icon28ChevronLeft />
              </button>
              <button disabled={scrollPosition === 'next'} className={styles.showcaseOffers__contentNavButton} onClick={() => scrollContentNext()}>
                <Icon28ChevronLeft style={{ transform: 'rotateY(180deg)' }}/>
              </button>
            </div>)}
        </div>)}
      <div className={styles.container}>{renderTabs()}</div>

      {renderOffers()}
    </div>);
};
